// Variables
$white: #ffffff;
$gray: #a2a1a1;
$yellow: #f4cd15;
$green: #35f072;
$red: #e8a9a0;
$blue: #28b4f8;

.upBtn {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 40px;
  right: 40px;
  background-color: #257fd3;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.upBtn-icon {
  margin-top: 5px;
}
