// Global styles
a {
  color: inherit;
  text-decoration: none;
}
.notification {
  position: absolute;
  width: 300px;
  top: 80px;
  right: 30px;
  z-index: 1;
  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #cd5656;
    cursor: pointer;
  }
}

.ReactModalPortal {
  position: fixed;
  z-index: 99999;
  display: flex;
  align-items: center;
}

.ReactModal__Content.ReactModal__Content--after-open {
  width: 100%;
  display: grid;
  width: 100%;
  align-items: center;

  width: 1100px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.customButton {
  background: #2196f3;
  color: #fff;
  border: 0;
  box-shadow: none;
  padding: 5px 15px;
}

.editorClassName.rdw-editor-main {
  min-height: 500px;
  border: 1px solid #efefef;
  padding: 10px;
}

.InsertMailHead {
  display: flex;
  align-items: center;
  gap: 10px;
}

.modal_contents {
  width: 100%;
  min-width: 1000px;
  min-height: 600px;
}

.modal_contents textarea {
  width: 100%;
  min-height: 600px;
}
.modal_contents_buttons {
  display: flex;
  gap: 15px;
}

.modal_contents_buttons button {
  padding: 10px 20px;
  border: 0;
  background: #2196f3;
  color: #fff;
}

.InsertMailHead {
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-width: 500px;
}

.select-menu {
  display: none; /* Menüyü varsayılan olarak gizle */
}

.select-menu.open {
  display: block; /* Menü açıldığında görünür yap */
}
