.coverimage {
  background: url("../../assets/images/dasboard.jpg") no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}
.loginform {
  background: #fff;
  padding: 20px;
  margin-top: 100px;
  border-radius: 10px;
}
