@import "../Layout/index.scss";
.lead-item {
  &.white {
    border-color: $white;
  }
  &.gray {
    border-color: $gray;
    background-color: #d9d9d9;
  }
  &.yellow {
    border-color: $yellow;
    background-color: #f5dd86;
  }
  &.green {
    border-color: $green;
    background-color: #a4fbc1;
  }
  &.red {
    border-color: $red;
    background-color: #f4b5a8;
  }
  &.blue {
    border-color: $blue;
    background-color: #97d9f1;
  }
  .details {
    margin-bottom: 10px;
    span {
      display: inline-block;
      &:nth-child(1) {
        width: 80px;
      }
      &:nth-child(2) {
        font-weight: bold;
      }
    }
  }
  .details-row {
    margin-bottom: 40px;

    span {
      display: block;
      &:nth-child(1) {
        width: 150px;
      }
      &:nth-child(2) {
        font-weight: bold;
      }
      &:nth-child(3) {
        font-weight: bold;
      }
      &:nth-child(4) {
        font-weight: bold;
      }
    }
  }

  .change-date {
    font-size: 12px;
    margin-right: 2px;
  }
  .lead-status {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    .button {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border: 0.1px solid black;
    }
    .active {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 50%;
      &.white {
        background-color: rgba($color: $white, $alpha: 0.2);
      }
      &.gray {
        background-color: rgba($color: $gray, $alpha: 0.2);
      }
      &.yellow {
        background-color: rgba($color: $yellow, $alpha: 0.2);
      }
      &.green {
        background-color: rgba($color: $green, $alpha: 0.2);
      }
      &.red {
        background-color: rgba($color: $red, $alpha: 0.2);
      }
      &.blue {
        background-color: rgba($color: $blue, $alpha: 0.2);
      }
    }
  }
  .expand-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    svg {
      transition: all 0.2s;
      transform: rotate(0);
    }
    &.active {
      svg {
        transform: rotate(45deg);
      }
    }
  }

  .status-label {
    font-weight: bold;
    padding: 10px 14px;
    border-radius: 10px;
    margin: 0 10px;
  }
  .customer-label {
    font-weight: bold;
    margin-left: 20px;
  }
  .lead-detail {
    position: relative;
    border-top: 1px solid $gray;
    padding-top: 40px;
    margin-top: 40px;

    .edit-button {
      position: absolute;
      top: 10px;
      right: 0;
    }
  }
  .lead-directions {
    height: 40px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    position: relative;
    cursor: pointer;
    .button {
      width: 18px;
      height: 18px;
      border-radius: 50%;
    }
  }

  .mile-button {
    display: inline-flex;
    cursor: pointer;
    color: #000;
    &.active {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: rgba($color: $blue, $alpha: 0.5);
    }
  }
  .vehicle-condition {
    display: flex;
    &.NotRunning {
      color: red;
    }
    &.Enclosed {
      border-style: solid;
    }
  }
  .phone-number {
    color: #000;
    text-decoration: none;
  }
  .vehicle-list {
    a {
      color: #000;
      text-decoration: none;
    }
  }
}

.status-white {
  background-color: $white;
}
.status-gray {
  background-color: $gray;
}
.status-yellow {
  background-color: $yellow;
}
.status-green {
  background-color: $green;
}
.status-red {
  background-color: $red;
}
.status-blue {
  background-color: $blue;
}
.notelist {
  height: 400px;
  overflow-y: scroll;
}
.notearea {
  margin-top: 4px !important;
}
div[editext="main-container"] {
  display: inline-flex !important;
}

.rdw-editor-toolbar {
  position: sticky;
  top: 0;
  z-index: 99;
}
